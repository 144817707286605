import {defaultsDeep, isPlainObject, isString, isArray} from 'lodash-es';

window._internal_ ??= {};

const recent_messages = [];

const MAX_LOG_LENGTH = 500;

const LEVELS = {
  TRACE: 'trace',
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
};

const process_msg = msg => {
  if (recent_messages.length > MAX_LOG_LENGTH) {
    recent_messages.shift();
  }

  try {
    //const cloned = JSON.parse(JSON.stringify(msg));
    //recent_messages.push(cloned);
    recent_messages.push(msg);
  }
  catch (err) {
    console.error(err);
  }

  if (msg.level === LEVELS.INFO) {
    console.info(msg);
  }
  else if (msg.level === LEVELS.WARN) {
    console.warn(msg);
  }
  else if (msg.level === LEVELS.ERROR) {
    const has_stack = msg.stack !== undefined;
    if (has_stack) {
      const {stack, ...rest} = msg;
      console.info(rest);
      console.error(stack);
    }
    else {
      console.error(msg);
    }
  }
};

const format_msg = (level, ...args) => {
  const result = {
    level,
    ts: new Date().toISOString()
  };

  let message = null;
  for (const arg of args) {
    if (arg instanceof Error) {
      result.message = arg.message;
      if (arg.stack) {
        result.stack = arg.stack;
      }
      for (const [k, v] of Object.entries(arg)) {
        result[k] = v;
      }
    }
    else if (isString(arg)) {
      message = message ? `${message} ${arg}` : arg;
    }
    else if (isPlainObject(arg)) {
      for (const [k, v] of Object.entries(arg)) {
        result[k] = v;
        if (k === 'message') {
          message = v;
        }
      }
    }
    else if (arg === undefined) {
      continue;
    }
  }

  if (message) {
    result.message ??= message;
  }

  return result;
};

class Logger {
  trace(...args) {
    const msg = format_msg(LEVELS.TRACE, ...args);
    process_msg(msg);
  }

  debug(...args) {
    const msg = format_msg(LEVELS.DEBUG, ...args);
    process_msg(msg);
  }

  info(...args) {
    const msg = format_msg(LEVELS.INFO, ...args);
    process_msg(msg);
  }

  warn(...args) {
    const msg = format_msg(LEVELS.WARN, ...args);
    process_msg(msg);
  }

  error(...args) {
    const msg = format_msg(LEVELS.ERROR, ...args);
    process_msg(msg);
  }
}

const default_log = new Logger();
window.log = default_log;

export default Logger;
